<template>
    <div>
		<b-modal ref="modalCourrierFluxPaillettes" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("horse.print_infos_horse") }}
				</template>
			</template>

			<div class="col-12">
				<div class="form-group">
					<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
					<e-select
						v-model="template"
						id="model_id"
						track-by="model_label"
						label="model_label"
						:placeholder="labelTitleTemplate"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:options="templates"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("global.print") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Model from "@/mixins/Model.js"
import StockSemence from '@/mixins/StockSemence.js'

export default {
	name: "ModalCourrierFluxPaillettes",
	mixins: [Model, StockSemence],
	data () {
		return {
			templates: [],
			template: null,
			processing: false,
			tos: [],

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			this.template = null
			this.templates = await this.loadAllModel(0, ['flux_paillettes'])
		},
		openModal(params) {
			this.tos = []
			params.forEach(element => {
				if(element.to && element.to.tiers_id && this.tos[element.to.tiers_id]) {
					this.tos[element.to.tiers_id].semenceemplacement_ids.push(element.uid)
				}
				else if(element.to && element.to.tiers_id) {
					this.tos[element.to.tiers_id] = {}
					this.tos[element.to.tiers_id].semenceemplacement_ids = [element.uid]
				}
			})

			this.$refs.modalCourrierFluxPaillettes.show()
		},
		async checkForm() {
			if(this.template) {
				this.processing = true
				this.tos.forEach((to, key) => {
					this.printCourrierFluxPaillettes(to.semenceemplacement_ids, this.template.model_id, key)
					.then(() => {
						this.processing = false
					})
				})
			}
			else {
				this.failureToast()
			}
		}
	}
}
</script>